@if (user && profile) {
<div class="d-flex justify-content-end mt-2">
  Status:
  <div class="ms-2 me-2">{{ getStatus() }}</div>
  <mat-icon>{{ getStatusIcon() }}</mat-icon>
</div>

<div style="display: flex; justify-content: space-around">
  <div>
    <p><strong>User Input:</strong></p>
    <div class="d-flex justify-content-center">
      @if (profile.image) {

      <img class="profile-picture" [src]="profile.image" alt="profile image" />

      } @else {
      <mat-icon class="profile-picture">person</mat-icon>
      }
    </div>
    <display-input title="ID Number" [value]="profile.idNumber" />
    <display-input title="First Name" [value]="profile.firstName" />
    <display-input title="Last Name" [value]="profile.lastName" />
    <display-input title="Mobile Number" [value]="user.mobile" />
    <display-input title="ID Type" [value]="profile.idType" />
    <display-input title="Gender" [value]="profile.gender" />
    <display-input title="Nationality" [value]="profile.nationality" />
  </div>
  @if (biometricResult) {
  <div>
    <p><strong>HANIS:</strong></p>
    <div class="d-flex justify-content-center">
      @if (biometricResult.photo) {

      <img
        class="profile-picture"
        [src]="biometricResult.photo"
        alt="biometric image"
      />

      } @else {
      <mat-icon class="profile-picture">person</mat-icon>
      }
    </div>
    <div
      [ngClass]="{
        'background-error': biometricResult.dhaVerified !== 'true',
        'background-success': biometricResult.dhaVerified === 'true'
      }"
    >
      <display-input title="ID Number" [value]="biometricResult.idNumber" />
      <display-input title="First Name" [value]="biometricResult.firstNames" />
      <display-input title="Last Name" [value]="biometricResult.surname" />
      <display-input
        title="HANIS Reference"
        [value]="biometricResult.hanisReference"
      />
      <display-input
        title="Date of Birth"
        [value]="biometricResult.dateOfBirth"
      />
      <display-input title="Gender" [value]="biometricResult.gender" />
      <display-input title="Verified" [value]="biometricResult.dhaVerified" />
    </div>
  </div>
  } @if (asylumResult) {
  <div>
    <p><strong>NIIS:</strong></p>
    <div class="d-flex justify-content-center">
      @if (asylumResult.photo) {
      <img
        class="profile-picture"
        [src]="asylumResult.photo"
        alt="biometric image"
      />
      } @else {
      <mat-icon class="profile-picture">person</mat-icon>
      }
    </div>
    <div
      [ngClass]="{
        'background-error': !isAsylumValid,
        'background-success': isAsylumValid
      }"
    >
      <display-input title="File Number" [value]="asylumResult.fileNumber" />
      <display-input title="First Name" [value]="asylumResult.firstName" />
      <display-input title="Last Name" [value]="asylumResult.surname" />
      <display-input title="Status" [value]="asylumResult.status" />
      <display-input title="Date of Birth" [value]="asylumResult.birthDate" />
      <display-input title="Gender" [value]="asylumResult.gender" />
      <display-input title="Nationality" [value]="asylumResult.nationality" />
      <display-input title="Expiry Date" [value]="asylumResult.expiryDate" />
      <display-input
        title="Office Of Application"
        [value]="asylumResult.officeOfApplication"
      />
    </div>
  </div>
  }
</div>

@if(user.carTrackDriverId){
<div
  style="
    text-align: center;
    padding: 22px;
    background-color: green;
    color: white;
  "
>
  Car Track Driver ID: <strong>{{ user.carTrackDriverId }}</strong>
</div>
}

<div class="d-flex align-items-center justify-content-between mt-3">
  @if (loading) {
  <mat-progress-spinner
    class="me-2"
    mode="indeterminate"
    color="primary"
    diameter="30"
  />
  } @else {

  <div>
    <button
      (click)="onCreateCarTrackProfile()"
      mat-flat-button
      color="primary"
      class="px-4"
      [disabled]="loading || !!user.carTrackDriverId"
    >
      Create Car Track Profile
    </button>
  </div>

  <div>
    <button
      (click)="onDeclineClicked()"
      [disabled]="profile.status == 'declined'"
      mat-raised-button
      color="warn"
      class="me-3 px-4"
    >
      Decline
    </button>
    <button
      (click)="onApproveClicked()"
      [disabled]="profile.status == 'approved'"
      mat-raised-button
      class="px-4"
    >
      Approve
    </button>
  </div>
  }
</div>
} @if (!profile) {
<div class="d-flex justify-content-center align-items-center h-100">
  <mat-icon class="me-2">person</mat-icon>
  <div>This user has not completed their profile yet</div>
</div>
}
